
import React from "react"
import NavMenu from "./NavMenu"

const Menu = () => {
    return (
        <>
            <div className="container-fluid bg-primary text-white">
                <div className="container text-center fs-6">Contactanos de lunes a viernes de 16h a 20h en el telf. 971 459 777</div>
            </div>
            <NavMenu />
        </>
    )
}

export default Menu
