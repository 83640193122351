import React from 'react'
import Footer from '../components/Footer/Footer';
import Menu from '../components/Menu';

const LayoutBase = ( { children } ) => {
   

    return (
        <>
            <Menu />
            { children }
            <Footer/>
        </>
    )
}

export default LayoutBase
