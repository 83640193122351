import React from 'react'
import LiItem from './LiItem'
import Social from '../Social/Social'
import { ReactComponent as Logo } from '../../images/logo.svg'
import './NavMenu.scss'
import { Link } from 'gatsby'

const NavMenu = () => {

    return (
        <header className="container py-lg-4">
            
            <div className="d-flex d-lg-none justify-content-center pt-4">
                {/* Menu movil*/}
                <Link to="/">
                    <Logo className="logo"/>
                </Link>
            </div>

            <nav className="navbar navbar-expand-lg navbar-light">
                
                { /* Toggle button */ }

                <div className="container d-flex d-lg-none justify-content-center my-3">

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>

                <div className="collapse navbar-collapse" id="navbarNav">
                    { /* Logo desktop */ }
                    <div className="d-none d-lg-flex justify-content-center">
                        <Link to="/">
                            <Logo className="logo" />
                        </Link>
                    </div>

                    { /* Sections menu */ }
                    <ul className="d-flex navbar-nav text-center justify-content-center w-100 bg-hover-items ">
                        { /* Items Menu */ }
                        <LiItem name="Inicio" path="/" />
                        <LiItem name="Nosotros" path="/nosotros" />
                        {/* <LiItem name="Blog" path="/blog" /> */}
                        <LiItem name="El Centro" path="/el-centro" />
                        <LiItem name="Servicios" path="/servicios" />
                        <LiItem name="Terapias" path="/terapias" />
                        <LiItem name="Contacto" path="/contacto" />
                    </ul>
                </div>

                {/* rrss inside menu */ }
                <div className="d-none d-lg-flex justify-content-center text-primary w-md-100">
                    <Social />
                </div>
            </nav>
        </header>
    )
}

export default NavMenu
