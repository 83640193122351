import React from 'react'
import { Link } from 'gatsby'

const LiItem = ( props ) => {

    const { name, path } = props;

    return (
        <>
            <li className="nav-item mx-2">
                <Link to={path} className="nav-link" activeClassName="active" aria-current="page">{name}</Link>
            </li>
        </>
    )
}

export default LiItem
