import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Social from "../Social/Social"
import './Footer.scss'

const Footer = () => {
    return (
        <footer className="container-fluid bg-secondary text-white">
            <div className="container">
                <div className="row py-5">
                    
                    {/*Columna 1 */}
                    <div className="col-12 col-lg-6">
                        <StaticImage
                            placeholder="none"
                            src="../../images/logo-white.png"
                            alt="Centro desarrollo y lenguaje"
                        />
                        <p className="pt-3">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </p>
                    </div>

                    {/*Columna 2 */}
                    <div className="col-12 col-lg-4 ">

                        <div className="d-flex gap-2 mb-3">
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-geo-alt-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                    </svg>
                                </div>
                                <div>
                                    C/Sant Joaquim, 8 bjs D<br />
                                    07003 Palma de Mallorca.<br />
                                    Baleares. España.
                                </div>
                        </div>

                        <div className="d-flex gap-2 mb-3">
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-phone"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"
                                    />
                                    <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                </svg>
                            </div>
                            <div>
                                971 459 777
                            </div>
                        </div>

                        <div className="d-flex gap-2">
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-calendar3"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"
                                    />
                                    <path
                                        d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                                    />
                                </svg>
                            </div>
                            <div>
                                <p><span className="fw-bold">Horario atención</span>Lunes a Viernes de 16h a 20h</p>
                            </div>
                        </div>
                    </div>

                    {/*Columna 3 */}
                    <div className="col-12 col-lg-2">
                        <ul>
                            <li>
                                Siguenos
                            </li>
                            
                            <li className="redes my-3 white">
                                <Social className="white"/>
                            
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
